import HttpClient from '../../../js/utils/HttpClient';
import Button from '../../atoms/button/Button';
import FormField from '../../molecules/form-field/FormField';

type JSONValue = string | number | boolean | null | Record<string, string>;

type JSONObject = {
    [key: string]: JSONValue | JSONValue[];
};

export type AjaxResponseError = {
    id: string;
    message: string;
};

export type AjaxResponse = {
    message: string;
    data?: JSONObject;
    errors?: AjaxResponseError[];
};

export default abstract class AjaxForm extends HTMLFormElement {
    protected submitting = false;

    #handleSubmit = async (event: Event) => this.submitForm(event);

    get #submitButtons(): NodeListOf<HTMLButtonElement> {
        return this.querySelectorAll('button[type="submit"]');
    }

    protected connectedCallback(): void {
        this.addEventListener('submit', this.#handleSubmit);
    }

    protected disconnectedCallback(): void {
        this.removeEventListener('submit', this.#handleSubmit);
    }

    public reset(): void {
        super.reset();

        const allFormFields: FormField[] = Array.from(this.querySelectorAll('nh-form-field'));
        for (const formField of allFormFields) {
            formField.clearErrorState();
        }
    }

    public async submitForm(event?: Event): Promise<boolean> {
        event?.preventDefault();

        if (this.submitting) return false;

        this.disableSubmitButtons();

        const action = this.getAttribute('action');
        if (action === null) {
            throw new Error('No action was set for this AjaxForm');
        }

        const formData = new FormData(this);
        const data = HttpClient.convertFormDataToQueryString(formData);

        let returnStatus = true;
        try {
            const response = await HttpClient.post(action, data);
            this.onSuccess(response);
        } catch (error) {
            this.onError(error as Error);
            returnStatus = false;
        }

        this.enableSubmitButtons();
        return returnStatus;
    }

    public enableSubmitButtons(): void {
        this.submitting = false;
        this.#submitButtons.forEach((button) => {
            button.disabled = false;
            if (button instanceof Button) {
                button.loading = false;
            }
        });
    }

    public disableSubmitButtons(): void {
        this.submitting = true;
        this.#submitButtons.forEach((button) => {
            button.disabled = true;
            if (button instanceof Button) {
                button.loading = true;
            }
        });
    }

    protected removeErrorStatusPanels(): void {
        this.querySelectorAll('[data-role="error-status-panel"]')?.forEach((panel) =>
            panel.remove()
        );
    }

    protected setLoadingState(loading = true): void {
        const button: Button | null = this.dialog?.querySelector('[data-role="primary"]') || null;

        if (!button) {
            return;
        }

        button.disabled = loading;
        button.loading = loading;
    }

    checkValidity(): boolean {
        const inputs: Array<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> =
            Array.from(this.querySelectorAll('input, select, textarea'));

        return inputs.filter((element) => !element.validity.valid).length === 0;
    }

    protected abstract onSuccess(response: Response): void;

    protected abstract onError(error: Error): void;
}
